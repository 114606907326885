<template>
  <div class="single-sensor" v-if="loaded">
    <Chart ref="chart" :chart-data="chartdata" :options="otherOptions" />
    <v-row justify="center" class="mt-3">
      <!-- <v-btn class="mx-1" :loading="realtimeButton" @click="realTime">Realtime</v-btn>
      <v-btn class="mx-1" :loading="oneDayButton" @click="getDateReadings(1, 'day')">24 hours</v-btn>
      <v-btn class="mx-1" :loading="threeDayButton" @click="getDateReadings(3, 'day')">3 days</v-btn>
      <v-btn class="mx-1" :loading="sevenDayButton" @click="getDateReadings(7, 'day')">7 days</v-btn>
      <v-btn class="mx-1" :loading="tenDayButton" @click="getDateReadings(10, 'day')">10 days</v-btn>-->
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Chart from "@/components/Chart";
import Alert from "@/components/Alert";
import Alarm from "@/components/Alarm";
export default {
  name: "device",
  data() {
    return {
      sensors: [],
      loaded: false,
      realtime: true,
      realtimeButton: false,
      twelveHourButton: false,
      oneDayButton: false,
      twoDayButton: false,
      threeDayButton: false,
      sevenDayButton: false,
      tenDayButton: false,
      fourteenDayButton: false,
      thirtyDayButton: false,
      currentData: [],
      dayReadings: "",
      chartdata: null,
      chartLow: 40,
      colors: [
        "green",
        "red",
        "orange",
        "teal",
        "black",
        "blue",
        "purple",
        "pink",
        "yellow"
      ],
      chartHigh: 45,
      sensorData: true,
      kdxOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                max: 200
              }
            }
          ]
        }
      },
      otherOptions: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          intersect: false
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                // unit: "minute",
                tooltipFormat: "MMM D, h:mm a",
                stepSize: 5
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                // source: "data"
                // suggestedMin: 30,
                // suggestedMax: 40,
                // min: 30,
                // max: 50,
                // stepSize: 2
              }
            }
          ]
        },
        legend: {
          display: true
        }
      }
    };
  },
  components: {
    Chart,
    Alert,
    Alarm
  },
  async created() {
    this.sensors = await this.$store.dispatch("sensor/testingSensors");
    this.setData();
    this.loaded = true;
  },
  computed: {
    // ...mapState("sensor", ["sensor", "deviceType"]),
    latestValues() {
      return this.currentData.map(item => item.min);
    },
    high() {
      return Math.max(...this.currentData.map(item => item.min));
    },
    low() {
      return Math.min(...this.currentData.map(item => item.min));
    },
    allData() {
      return this.sensors.map(item => {
        let temps = [];
        let vals = item.readings.items;
        if (vals.length) {
          temps = vals.map(item => {
            return {
              createdAt: item.createdAt,
              min: item.min
            };
          });
        }
        return {
          name: item.name,
          uuid: item.uuid,
          readings: temps
        };
      });
    },
    labelValues() {
      return this.sensors.map(item => item.name);
    },
    allDatasets() {
      return this.allData.map((item, i) => {
        return {
          label: `${item.name}`,
          borderColor: this.colors[i],
          backgroundColor: this.colors[i],
          radius: 0,
          borderWidth: 2,
          fill: false,
          data: item.readings.map(item => {
            return {
              x: new Date(item.createdAt),
              y: item.min
            };
          })
        };
      });
    }
  },
  watch: {
    sensor(val) {
      if (this.loaded && val.length <= 20) {
        this.setData();
      } else if (this.loaded) {
        this.setData(val);
      }
    },
    dayReadings(val) {
      // let temps = val.map(item => item.min);
      // let high = Math.max(...temps);
      // let low = Math.min(...temps);
      // let readings = val.filter(item => item.min === high || item.min === low);
      // console.log(readings.length);
      // this.setData(val);
    },
    otherOptions: {
      handler(val) {
        if (this.$refs.chart) {
          this.$refs.chart.renderChart(
            this.chartdata,
            this.deviceType === "KDX" ? this.kdxOptions : this.otherOptions
          );
        }
      },
      deep: true
    },
    high(val) {
      if ((val - this.low) % 2 === 0) {
        this.otherOptions.scales.yAxes[0].ticks.max = val + 2;
      } else {
        this.otherOptions.scales.yAxes[0].ticks.max = val + 1;
      }
    },
    low(val) {
      this.otherOptions.scales.yAxes[0].ticks.min = val;
    }
  },
  methods: {
    async realTime() {
      if (!this.realtime) {
        this.realtimeButton = true;
        await this.$store.dispatch(
          "sensor/singleSensor",
          this.$route.params.id
        );
        this.otherOptions.scales.xAxes[0].time = {
          unit: "minute",
          stepSize: 5
        };
        this.realtime = true;
        this.realtimeButton = false;
      }
    },
    async getDateReadings(number, unit) {
      if (number === 12) {
        this.twelveHourButton = true;
      } else if (number === 1) {
        this.oneDayButton = true;
      } else if (number === 2) {
        this.twoDayButton = true;
      } else if (number === 3) {
        this.threeDayButton = true;
      } else if (number === 7) {
        this.sevenDayButton = true;
      } else if (number === 10) {
        this.tenDayButton = true;
      } else if (number === 14) {
        this.fourteenDayButton = true;
      } else if (number === 30) {
        this.thirtyDayButton = true;
      }
      await this.$store.dispatch("sensor/pullReadings", {
        id: this.sensor[0].readingSensorId,
        number,
        unit
      });
      if (number === 12) {
        this.twelveHourButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "hour",
          stepSize: 2
        };
      } else if (number === 1) {
        this.oneDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "hour",
          stepSize: 3
        };
      } else if (number === 2) {
        this.twoDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1
        };
      } else if (number === 3) {
        this.threeDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1
        };
      } else if (number === 7) {
        this.sevenDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1
        };
      } else if (number === 10) {
        this.tenDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1
        };
      } else if (number === 14) {
        this.fourteenDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 2
        };
      } else if (number === 30) {
        this.thirtyDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 2
        };
      }
      this.realtime = false;
    },
    colorStop(val) {
      if (this.low)
        if (this.low < val) {
          return 0;
        } else if (this.high > val) {
          return 1;
        } else {
          console.log("im here");
          // let range = this.high - this.low;
          // let low = val - this.low;
          // console.log(low / range, "range");
          // console.log("else");
          // console.log((val - this.low) / (this.high - this.low));
          // return (val - this.low) / (this.high - this.low);
        }
    },
    chartGradient(context) {
      let gradient = null;
      let width = null;
      let height = null;
      let chartArea = context.chart.chartArea;
      if (!chartArea) {
        return null;
      }
      let chartWidth = chartArea.right - chartArea.left;
      let chartHeight = chartArea.bottom - chartArea.top;
      if (gradient === null || width !== chartWidth || height !== chartHeight) {
        width = chartWidth;
        height = chartHeight;
        let ctx = context.chart.ctx;
        gradient = ctx.createLinearGradient(
          0,
          chartArea.bottom,
          0,
          chartArea.top
        );
        if (this.colorStop(this.chartLow) === 0) {
          gradient.addColorStop(0, "green");
        } else if (this.colorStop(this.chartHigh) === 1) {
          gradient.addColorStop(1, "red");
        } else if (
          this.colorStop(this.chartLow) > 0 &&
          this.colorStop(this.chartLow) < 1
        ) {
          console.log("yellow");
        }
      }
      return gradient;
    },
    getLatestValues() {
      return this.chartdata.datasets.map(item => {
        return item.data[item.data.length - 1];
      });
    },
    setData(data) {
      this.chartdata = {
        datasets: this.allDatasets
      };
    }
  }
  //   beforeDestroy() {
  //     if (this.realtime) {
  //       this.$store.dispatch("sensor/emptySingleSensor");
  //     }
  //   }
};
</script>

<style lang="scss">
</style>